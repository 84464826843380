var $globalHeader = $(".global-header");

$(function () {
	let headerHeight = $globalHeader.outerHeight();
	$("body").css({
		"--header-height": headerHeight + "px",
	});
	window.headerHeight = headerHeight;

	$(window).on("scroll", function () {
		var scrollTop = window.pageYOffset || $(document).scrollTop();
		$globalHeader.toggleClass("scrolling", scrollTop > 0);
	});

	try {
		const menuLeftMapping = new MappingListener({
			selector: ".menu-wrapper-left",
			mobileWrapper: ".menu-mobile-body",
			mobileMethod: "appendTo",
			desktopWrapper: ".header-left",
			desktopMethod: "appendTo",
			breakpoint: 1025,
		}).watch();

		const menuRightMapping = new MappingListener({
			selector: ".menu-wrapper-right",
			mobileWrapper: ".menu-mobile-body",
			mobileMethod: "appendTo",
			desktopWrapper: ".header-right",
			desktopMethod: "prependTo",
			breakpoint: 1025,
		}).watch();

		const externalLinkMapping = new MappingListener({
			selector: ".external-link-wrapper",
			mobileWrapper: ".menu-mobile-body",
			mobileMethod: "appendTo",
			desktopWrapper: ".header-right",
			desktopMethod: "appendTo",
		}).watch();
	} catch (e) {
		console.log(e);
	}

	$(".close-menu-mobile, .menu-mobile-backdrop").on("click", function () {
		$(".menu-mobile").removeClass("show");
		$("body").removeClass("overflow-hidden");
		$(".menu-mobile-backdrop").fadeOut();
	});

	$(".menu-toggle").on("click", function () {
		$(".menu-mobile").addClass("show");
		$("body").addClass("overflow-hidden");
		$(".menu-mobile-backdrop").fadeIn();
	});

	$(".toggle-sub-menu").on("click", function () {
		console.log("click");

		$(this).toggleClass("active");
		$(this).closest(".has-sub").find(".sub-menu").slideToggle();
	});
});
