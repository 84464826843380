import LoopSlider from "./LoopSlider";
import Swiper from "swiper";
import {
	Pagination,
	Autoplay,
	Navigation,
	EffectFade,
	Thumbs,
	Scrollbar,
} from "swiper/modules";

$(document).ready(() => {
	const bannerSlider = new Swiper(".banner-slider .swiper", {
		modules: [Autoplay, Navigation, EffectFade],
		slidesPerView: 1,
		loop: true,
		effect: "fade",
		speed: 2000,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: ".banner-slider .swiper-btn-next",
			prevEl: ".banner-slider .swiper-btn-prev",
		},
	});

	const storySlider = new Swiper(".story-slider .swiper", {
		modules: [Navigation],
		slidesPerView: 1.25,
		spaceBetween: 16,
		centeredSlides: true,
		loop: true,
		navigation: {
			nextEl: ".story-slider .swiper-btn-next",
			prevEl: ".story-slider .swiper-btn-prev",
		},
		breakpoints: {
			768: {
				slidesPerView: 2.5,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 26,
				centeredSlides: false,
			},
		},
	});

	const partnerSlider = new Swiper(".partner-slider .swiper", {
		modules: [Autoplay, Navigation],
		slidesPerView: 2.75,
		spaceBetween: 12,
		loop: true,
		speed: 4000,
		autoplay: {
			delay: 0,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: ".partner-slider .swiper-btn-next",
			prevEl: ".partner-slider .swiper-btn-prev",
		},
		breakpoints: {
			576: {
				slidesPerView: 4.5,
			},
			768: {
				slidesPerView: 5.5,
			},
			1024: {
				slidesPerView: 9,
				spaceBetween: 32,
			},
		},
	});

	const careerSlider = new Swiper(".career-slider .swiper", {
		modules: [Pagination],
		slidesPerView: 1.25,
		spaceBetween: 8,
		centeredSlides: true,
		loop: true,
		pagination: {
			el: ".career-slider .swiper-pagination",
			clickable: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2.25,
			},
			768: {
				slidesPerView: 3.25,
			},
			1024: {
				slidesPerView: 4,
				centeredSlides: false,
				spaceBetween: 16,
			},
		},
	});

	const singleSlider = new LoopSlider(".single-slider", {
		1024: {
			spaceBetween: 40,
			slidesPerView: 1,
		},
	});

	const doubleSlider = new LoopSlider(".double-slider", {
		576: {
			spaceBetween: 20,
			slidesPerView: 1.5,
		},
		1024: {
			spaceBetween: 40,
			slidesPerView: 2,
		},
	});

	const tripleSlider = new LoopSlider(".triple-slider", {
		576: {
			spaceBetween: 20,
			slidesPerView: 2.5,
		},
		1024: {
			spaceBetween: 40,
			slidesPerView: 3,
		},
	});

	const quadrupleSlider = new LoopSlider(".quadruple-slider", {
		576: {
			spaceBetween: 20,
			slidesPerView: 2.5,
		},
		768: {
			spaceBetween: 20,
			slidesPerView: 3.5,
		},
		1024: {
			spaceBetween: 40,
			slidesPerView: 4,
		},
	});
});
