import { Fancybox } from "@fancyapps/ui";
import "./modules/Header";
import "./modules/Slider";
import "./modules/Accordion";
import "./modules/BackToTop";
import "./modules/CustomSelect";

var lazyLoadInstance = new LazyLoad({
	// Your custom settings go here
});

window.lazyLoadInstance = lazyLoadInstance;

Fancybox.bind("[data-fancybox]", {
	// Your custom options
});

$(function () {});
